import { Typography } from 'components/DesignSystemV2/Typography';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import clsx from 'classnames';

const Wrapper = styled.button`
  text-decoration: none;
  color: ${colorsV2.neutral180};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 12px;
  padding: 8px 12px;
  margin-top: auto;
  background-color: transparent;
  transition: 0.3s ease;
  cursor: pointer;

  &:hover:not(:disabled) {
    background-color: ${colorsV2.neutral5};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.block {
    width: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
`;

export const ButtonV3 = ({
  children,
  color = 'secondary',
  variant = 'outline',
  block = false,
  leftIcon,
  rightIcon,
  ...props
}) => {
  return (
    <Wrapper
      className={clsx({
        primary: color === 'primary',
        secondary: color === 'secondary',
        outline: variant === 'outline',
        block
      })}
      {...props}
    >
      {leftIcon && <IconWrapper>{leftIcon}</IconWrapper>}
      <Typography variant="semi-bold/14-20" color="inherit" type="span">
        {children}
      </Typography>
      {rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
    </Wrapper>
  );
};
