import { Typography } from 'components/DesignSystemV2/Typography';
import {
  IconsContentBookRead,
  IconsCourseGoal,
  IconsTimeCalendarScheduleDate,
  IconsUserUserFrame
} from 'components/Icon/SVGIcons';
import CheckMarkIcon from 'components/Icon/SVGIcons/CheckMark';
import React, { useMemo } from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { HorizontalArrow, VerticalArrow } from '../Animations';
import { Link } from 'gatsby';
import { ButtonV3 } from 'components/ButtonV3';
import { hexWithAlpha } from 'utils/color';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { toScreen } from 'utils/media-query/responsive.util';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  order: ${({ $order }) => $order};

  .animated-arrow {
    ${toScreen(desktopFirstV3.sm)} {
      visibility: hidden;
    }
  }
`;

const LabelWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 20px;
  border-radius: 20px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.red40};
  box-shadow: 0 16px 24px 0 rgba(212, 37, 37, 0.05),
    0 2px 6px 0 rgba(212, 37, 37, 0.05);
`;

const Index = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: ${colorsV2.red10};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  flex: 1;
  padding: 26px;
  border-radius: 24px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.neutral20};
  position: relative;
  transition: 0.3s ease;

  &:hover {
    border-color: ${colorsV2.primary100};
    box-shadow: 0px 24px 32px 0px ${hexWithAlpha(colorsV2.primary100, 0.05)},
      0px 16px 24px 0px ${hexWithAlpha(colorsV2.primary100, 0.05)},
      0px 4px 8px 0px ${hexWithAlpha(colorsV2.primary100, 0.05)};

    &::before {
      width: 60%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -1px; /* to overlap with the border */
    left: 50%;
    width: 46px;
    height: 1px;
    background-color: ${colorsV2.primary100};
    transform: translateX(-50%);
    transition: 0.3s ease;
  }
`;

const CourseSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const GoalList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const CourseItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const HArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-25%, -50%);
`;

const VArrowWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  ${({ $side }) => $side === 'right' && 'left: 100%;'}
  ${({ $side }) => $side === 'left' && 'right: 100%;'}
  transform: translate(${({ $side }) =>
    $side === 'right' ? '-50%' : '50%'}, 25%);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-top: auto;
`;

const InfoIcon = ({ type }) => {
  const props = { color: '#2B52D4' };
  switch (type) {
    case 'duration':
      return <IconsTimeCalendarScheduleDate {...props} />;
    case 'target':
      return <IconsUserUserFrame {...props} />;
    case 'outcome':
      return <IconsCourseGoal {...props} />;
  }
  return null;
};

const CourseInfoItem = ({ type, value }) => {
  return (
    <CourseItem>
      <IconWrapper>{<InfoIcon type={type} />}</IconWrapper>
      <Typography variant="regular/16-24" color={colorsV2.neutral180}>
        {value}
      </Typography>
    </CourseItem>
  );
};

export const RoadmapItem = ({
  name,
  summary,
  goals,
  duration,
  target,
  outcome,
  index,
  totalRows,
  url
}) => {
  const info = { duration, target, outcome };
  const row = Math.floor(index / 2);
  const col = index % 2;

  const order = useMemo(() => {
    if (row % 2 === 1) {
      return row * 2 + (2 - col);
    }
    return index + 1;
  }, [row, index, col]);

  const hDirection = row % 2 === 0 ? 'right' : 'left';
  const vSide = order % 2 === 0 ? 'right' : 'left';

  return (
    <ItemWrapper $order={order + 1}>
      <LabelWrapper>
        {order % 2 === 1 && (
          <HArrowWrapper>
            <HorizontalArrow
              className="animated-arrow"
              direction={hDirection}
            />
          </HArrowWrapper>
        )}

        {row > 0 && row < totalRows && col === 0 && (
          <VArrowWrapper $side={vSide}>
            <VerticalArrow className="animated-arrow" side={vSide} />
          </VArrowWrapper>
        )}
        <Label>
          <Index>
            <Typography variant="bold/20-28" color={colorsV2.red100}>
              {index + 1}
            </Typography>
          </Index>
          <div>
            <Typography variant="regular/14-16" color={colorsV2.neutral140}>
              Khoá học
            </Typography>
            <Typography variant="semi-bold/20-28" color={colorsV2.primary100}>
              {name}
            </Typography>
          </div>
        </Label>
      </LabelWrapper>
      <Content>
        <CourseSection>
          <Typography variant="semi-bold/14-20" color={colorsV2.neutral180}>
            MỤC TIÊU
          </Typography>
          <Typography variant="regular/16-24" color={colorsV2.neutral140}>
            {summary}
          </Typography>
          <GoalList>
            {goals.map((goal, index) => (
              <CourseItem key={index}>
                <IconWrapper>
                  <CheckMarkIcon />
                </IconWrapper>
                <Typography variant="regular/16-24" color={colorsV2.neutral180}>
                  {goal}
                </Typography>
              </CourseItem>
            ))}
          </GoalList>
        </CourseSection>
        <CourseSection>
          <Typography variant="semi-bold/14-20" color={colorsV2.neutral180}>
            THÔNG TIN HỌC TẬP
          </Typography>
          <InfoWrapper>
            {Object.keys(info).map(key => (
              <CourseInfoItem key={key} type={key} value={info[key]} />
            ))}
          </InfoWrapper>
        </CourseSection>
        {url ? (
          <StyledLink to={url}>
            <ButtonV3 block leftIcon={<IconsContentBookRead />}>
              Chi tiết khoá học
            </ButtonV3>
          </StyledLink>
        ) : (
          <ButtonV3 block disabled leftIcon={<IconsContentBookRead />}>
            Chi tiết khoá học
          </ButtonV3>
        )}
      </Content>
    </ItemWrapper>
  );
};
