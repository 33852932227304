import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import MySEO from 'shared/MySEO';
import AboutIeltsJunior from 'shared/IeltsJunior/AboutIeltsJunior';
import { SaleBannerSectionBase } from 'shared/IeltsJunior/SaleBannerSection';
import IeltsJuniorFAQ from 'shared/IeltsJunior/IeltsJuniorFAQ';
import { IeltsJuniorV3Header } from 'shared/IeltsJuniorNew/Header';
import RegisterForm from 'shared/IeltsJunior/RegisterForm';
import { useModal } from 'hooks/useModal';
import { v4 as uuidv4 } from 'uuid';
import { UserPixelTracking } from 'utils/userPixelTracking';
import { IeltsJuniorHighlight } from 'shared/IeltsJuniorNew/Highlight';
import { IeltsJuniorOverview } from 'shared/IeltsJuniorNew/Overview';
import { IeltsJuniorRoadmap } from 'shared/IeltsJuniorNew/Roadmap';
import { graphql } from 'gatsby';
import { IeltsJuniorAdvantage } from 'shared/IeltsJuniorNew/Advantage';
import HonorRoll from 'components/HonorRoll';
import LinearThinkingReviews from 'components/LinearThinkingReviews';
import { SectionV3 } from 'components/SectionV3';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { colorsV2 } from 'style/colors-v2';

const IeltsJunior = ({ location, data }) => {
  const { isOpened, openModal, closeModal } = useModal();
  const { content } = data.gcms.jsonContanier;
  function handleOpenRegisterForm() {
    const uid = uuidv4();
    UserPixelTracking.clickEventPixelSDK(uid);
    UserPixelTracking.clickEvent(uid);
    openModal();
  }

  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Chương trình DOL IELTS Junior dành cho học sinh THCS"
        slug={location.pathname}
        seoTitle="Chương trình DOL IELTS Junior dành cho học sinh THCS"
        seoDesc="Chương trình phù hợp với phụ huynh muốn con: Chắc nền tiếng Anh học thuật, Đạt 8.0 IELTS từ lớp 8, 9, thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết, điểm cao Tiếng Anh trên trường, phát âm chuẩn, hay, nói lưu loát, tăng tư duy logic, phản biện và kiến thức xã hội"
        description="Chương trình phù hợp với phụ huynh muốn con: Chắc nền tiếng Anh học thuật, Đạt 8.0 IELTS từ lớp 8, 9, thành thạo 4 kỹ năng Nghe - Nói - Đọc - Viết, điểm cao Tiếng Anh trên trường, phát âm chuẩn, hay, nói lưu loát, tăng tư duy logic, phản biện và kiến thức xã hội"
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <FixedTopBarScholarshipScheduleNOffline />
      <IeltsJuniorV3Header
        onRegisterClick={handleOpenRegisterForm}
        data={content.header}
      />
      <IeltsJuniorHighlight data={content.highlights} />
      <IeltsJuniorOverview data={content.overview} />
      <IeltsJuniorAdvantage
        advantages={content.advantages}
        onRegisterClick={handleOpenRegisterForm}
      />
      <HonorRoll />

      <IeltsJuniorRoadmap
        data={content.roadmap}
        onRegisterClick={handleOpenRegisterForm}
      />

      <SectionV3 $bgColor={colorsV2.neutral5}>
        <ResponsiveContainer>
          <LinearThinkingReviews
            title="Cảm nhận của học viên tại DOL"
            subtitle="từ học viên và phụ huynh"
            reviewsCount="300+"
          />
        </ResponsiveContainer>
      </SectionV3>

      <AboutIeltsJunior data={content.parentReviews} />
      <SaleBannerSectionBase
        data={content.saleBanner}
        onRegisterClick={handleOpenRegisterForm}
      />
      <IeltsJuniorFAQ />
      <RegisterForm closeModal={closeModal} isOpen={isOpened} />
    </MainLayout>
  );
};

export const query = graphql`
  {
    gcms {
      jsonContanier(where: { name: "Ielts_Junior_Landing_V3" }) {
        content
      }
    }
  }
`;

export default React.memo(IeltsJunior);
