import React from 'react';
import styled from 'styled-components';
import { AdvantageItem } from './AdvantageItem';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { toScreen } from 'utils/media-query/responsive.util';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  width: 100%;

  ${toScreen(desktopFirstV3.sm)} {
    grid-template-columns: 1fr;
  }
`;

export const AdvantageList = ({ items }) => {
  return (
    <Grid>
      {items.map(item => (
        <AdvantageItem key={item.key} {...item} itemKey={item.key} />
      ))}
    </Grid>
  );
};
