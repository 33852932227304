import { Typography } from 'components/DesignSystemV2/Typography';
import {
  StarsIcon,
  BrainIcon,
  CoinIcon,
  ClockSparkleIcon,
  BuildingIcon,
  BookSparkleIcon
} from 'components/Icon/SVGIcons';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { SectionV3 } from 'components/SectionV3';
import React from 'react';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { toScreen } from 'utils/media-query/responsive.util';

const HighlightContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 48px;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  ${toScreen(desktopFirstV3.md)} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  ${toScreen(desktopFirstV3.sm)} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 24px;
  width: 44px;
  height: 44px;
  background-color: ${colorsV2.primary5};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled(Typography)`
  margin-bottom: 8px;
`;

const iconMap = {
  stars: <StarsIcon />,
  brain: <BrainIcon />,
  coin: <CoinIcon />,
  clock: <ClockSparkleIcon />,
  building: <BuildingIcon />,
  book: <BookSparkleIcon />
};

export const IeltsJuniorHighlight = ({ data }) => {
  return (
    <SectionV3>
      <ResponsiveContainer>
        <HighlightContainer>
          {data.map((highlight, index) => (
            <li key={index}>
              <IconWrapper>{iconMap[highlight.icon]}</IconWrapper>
              <Title variant="bold/20-28" color={colorsV2.neutral180}>
                {highlight.title}
              </Title>
              <Typography variant="regular/16-24" color={colorsV2.neutral120}>
                {highlight.description}
              </Typography>
            </li>
          ))}
        </HighlightContainer>
      </ResponsiveContainer>
    </SectionV3>
  );
};
