import React from 'react';
import styled from 'styled-components';
import { RoadmapItem } from './RoadmapItem';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { toScreen } from 'utils/media-query/responsive.util';

const RoadmapDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px 72px;
  max-width: 872px;

  ${toScreen(desktopFirstV3.sm)} {
    max-width: 100%;
    grid-template-columns: 1fr;
  }
`;

export const RoadmapDetails = ({ details }) => {
  return (
    <RoadmapDetailsWrapper>
      {details.map((detail, index) => (
        <RoadmapItem
          key={index}
          index={index}
          totalRows={Math.ceil(details.length / 2)}
          {...detail}
        />
      ))}
    </RoadmapDetailsWrapper>
  );
};
