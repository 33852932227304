import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import LinkButton from 'components/LinkButton';
import { useModal } from 'hooks/useModal';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { toScreen } from 'utils/media-query/responsive.util';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { imageCoverStyle } from 'style/css-style';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: ${colorsV2.blue5};
  border-radius: 32px;

  ${toScreen(desktopFirstV3.md)} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ImageWrapper = styled(motion.div)`
  width: 100%;
  aspect-ratio: 558 / 392;
  position: relative;

  img {
    ${imageCoverStyle('contain')}
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  padding-left: 72px;
  padding-right: 72px;
  margin-bottom: auto;

  ${toScreen(desktopFirstV3.md)} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ActionWrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    width: 0;
    height: 1px;
    background-color: ${colorsV2.blue100};
    transition: 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      left: 0;
      width: 100%;
    }
  }
`;

const ActionButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: 0;
  padding: 0;
`;

const ActionLink = styled(LinkButton)`
  color: ${colorsV2.blue100};
  padding: 0;

  &:hover {
    background-color: transparent;
  }
`;

export const AdvantageItem = ({
  itemKey,
  imageHandle,
  title,
  description,
  url,
  actionText,
  demoBookImages
}) => {
  const [index, setIndex] = useState(0);
  const { isOpened, openModal, closeModal } = useModal();
  function renderActionButton(key) {
    if (key === 'books') {
      return (
        <ActionButton onClick={openModal}>
          <Typography variant="regular/16-24" color={colorsV2.blue100}>
            {actionText}
          </Typography>
        </ActionButton>
      );
    }

    return (
      <ActionLink href={url} isExternal={url.startsWith('http')}>
        {actionText}
      </ActionLink>
    );
  }

  return (
    <ItemWrapper>
      <ImageWrapper
        whileHover={{
          scale: 1.02,
          rotateZ: 2,
          transition: { duration: 1, type: 'spring' }
        }}
      >
        <LazyImage handle={imageHandle} alt={title} />
      </ImageWrapper>
      <ContentWrapper>
        <Typography variant="semi-bold/24-32" color={colorsV2.neutral180}>
          {title}
        </Typography>
        <Typography variant="regular/16-24" color={colorsV2.neutral140}>
          {description}
        </Typography>
      </ContentWrapper>
      <ActionWrapper>{renderActionButton(itemKey)}</ActionWrapper>
      {itemKey === 'books' && isOpened && (
        <Lightbox
          mainSrc={demoBookImages[index]}
          nextSrc={demoBookImages[index + 1]}
          prevSrc={demoBookImages[index - 1]}
          onCloseRequest={() => {
            closeModal();
            setIndex(0);
          }}
          onMovePrevRequest={() => setIndex(index - 1)}
          onMoveNextRequest={() => setIndex(index + 1)}
          reactModalStyle={{
            overlay: {
              zIndex: 99999 // On top of Zalo & Messenger buttons
            }
          }}
        />
      )}
    </ItemWrapper>
  );
};
