import React from 'react';
import { SectionV3 } from 'components/SectionV3';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import Button from '@dolstaff/shared/es/Button';
import { BackgroundAnimation, ChildrenAnimation } from '../Animations';

const Section = styled(SectionV3)`
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
`;

const Wrapper = styled.div`
  max-width: 760px;
  margin: 0 auto;
  display: grid;
  row-gap: 24px;
  justify-items: center;
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const Description = styled(Typography)`
  max-width: 640px;
`;

const Cta = styled(Button)`
  border-radius: 8px;
`;

export const IeltsJuniorV3Header = ({ onRegisterClick, data }) => {
  const { title, description } = data;

  return (
    <Section>
      <Wrapper>
        <BackgroundAnimation />
        <Typography
          variant="bold/32-40"
          tabletVariant="bold/40-48"
          desktopVariant="bold/48-57"
          color={colorsV2.neutral180}
          as="h1"
          v3
        >
          {title}
        </Typography>
        <Description variant="medium/18-24" color={colorsV2.neutral120}>
          {description}
        </Description>
        <Cta size="large" type="primary" onClick={onRegisterClick}>
          <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
            Đăng ký sớm giữ chỗ
          </Typography>
        </Cta>
        <ChildrenAnimation />
      </Wrapper>
    </Section>
  );
};
