import { desktopFirstV3 } from 'style/breakpointsv3';
import styled from 'styled-components';
import { toScreen } from 'utils/media-query/responsive.util';

export const SectionV3 = styled.section`
  padding-top: 100px;
  padding-bottom: 100px;
  ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor};`}

  ${toScreen(desktopFirstV3.md)} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;
