import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { toScreen } from 'utils/media-query/responsive.util';
import Modal from '../CourseLanding/Modal';
import { IconsContentQuote } from 'components/Icon/SVGIcons';
import VideoInline from 'shared/VideoThumbnailInline/VideoInline';
import { LazyImage } from 'components/LazyImage';
import { VideoThumbnail } from 'components/VideoThumbnail';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { SectionV3 } from 'components/SectionV3';
import { ResponsiveContainer } from 'components/ResponsiveContainer';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.div`
  max-width: 400px;

  ${toScreen(desktopFirstV3.sm)} {
    max-width: 100%;
  }
`;

const HeaderSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  margin-top: 64px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;

  ${toScreen(desktopFirstV3.md)} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const VideoElement = styled(VideoInline)`
  video {
    border-radius: 8px;
  }
  > div {
    margin-bottom: 0;
  }
`;

const CardInfoItem = styled.div`
  border-radius: 24px;
  border: 1px solid ${colorsV2.neutral20};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${colorsV2.white100};
  transform: translate(-25%, -25%);
`;

const CardContent = styled.div`
  padding: 24px;
  display: flex;
  gap: 28px;
  flex: 1;

  ${toScreen(desktopFirstV3.sm)} {
    flex-direction: column;
    align-items: center;
  }
`;

const CardFooter = styled.div`
  padding: 24px;
  background-color: ${colorsV2.neutral5};
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top: 1px solid ${colorsV2.neutral20};
`;

const Description = styled.div`
  display: grid;
  gap: 12px;
  svg {
    width: 32px;
    height: 32px;
  }
`;
const Info = styled.div`
  margin-top: 12px;
  display: grid;
  gap: 8px;
`;

const ContentInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const LeftContentInfo = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 40px) auto;
  align-items: center;
  gap: 12px;
  flex: 1;
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${colorsV2.white100};
  box-shadow: 0px 12px 24px 0px #30427814, 0px 3px 6px 0px #3042780f;
`;

const Score = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 16px;
  box-shadow: 0px 12px 24px 0px #30427814, 0px 3px 6px 0px #3042780f;
  flex-shrink: 0;
`;

const AboutIeltsJuniorBase = ({ data }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState('');

  const {
    title,
    subheader: { title: subheaderTitle, description: subheaderDescription },
    reviews
  } = data;

  return (
    <SectionV3>
      <ResponsiveContainer>
        <Header>
          <HeaderTitle>
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              color={colorsV2.black100}
              v3
            >
              {title}
            </Typography>
          </HeaderTitle>
          <HeaderSubtitle>
            <Typography
              variant="semi-bold/24-32"
              color={colorsV2.neutral200}
              v3
            >
              {subheaderTitle}
            </Typography>
            <Typography variant="regular/14-20" color={colorsV2.neutral180} v3>
              {subheaderDescription}
            </Typography>
          </HeaderSubtitle>
        </Header>
        <Content>
          {reviews.map((review, idx) => (
            <CardInfoItem key={idx} className="card-info">
              <CardContent>
                <Description>
                  <IconWrapper>
                    <IconsContentQuote />
                  </IconWrapper>
                  <Typography
                    variant="regular/16-28"
                    color={colorsV2.neutral180}
                    v3
                  >
                    {review.description}
                  </Typography>
                </Description>
                <VideoThumbnail
                  width={130}
                  aspectRatio="130/233"
                  poster={review.thumbnailUrl}
                  onClick={() => {
                    setVideoUrl(review.videoUrl);
                    setShowModal(true);
                  }}
                />
              </CardContent>
              <CardFooter>
                <Info>
                  <Typography
                    variant="semi-bold/14-20"
                    color={colorsV2.neutral120}
                    v3
                  >
                    PHỤ HUYNH CỦA EM
                  </Typography>

                  <ContentInfo>
                    <LeftContentInfo>
                      <AvatarWrapper>
                        <LazyImage
                          className=""
                          src={review.avatar}
                          alt="student-avatar"
                        />
                      </AvatarWrapper>
                      <div>
                        <Typography
                          variant="semi-bold/16-24"
                          color={colorsV2.neutral180}
                          v3
                        >
                          {review.fullName}
                        </Typography>
                        <Typography
                          variant="regular/14-20"
                          color={colorsV2.neutral120}
                          v3
                        >
                          {review.schoolName}
                        </Typography>
                      </div>
                    </LeftContentInfo>
                    <Score>
                      <Typography
                        variant="bold/20-28"
                        color={colorsV2.primary100}
                        type="span"
                        v3
                      >
                        {review.score}
                      </Typography>
                      <Typography
                        variant="semi-bold/14-20"
                        color={colorsV2.neutralPrimary}
                        type="span"
                        v3
                      >
                        {review.type}
                      </Typography>
                    </Score>
                  </ContentInfo>
                </Info>
              </CardFooter>
            </CardInfoItem>
          ))}
        </Content>
        <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
          <VideoElement
            onContextMenu={e => e.preventDefault()}
            video={videoUrl}
            alt="talk-about-dol-english"
            controlsList="nodownload"
            isAutoPlay
          />
        </Modal>
      </ResponsiveContainer>
    </SectionV3>
  );
};

export default AboutIeltsJuniorBase;
