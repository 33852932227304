import { Typography } from 'components/DesignSystemV2/Typography';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { SectionV3 } from 'components/SectionV3';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { AdvantageList } from './AdvantageList';
import { LazyImage } from 'components/LazyImage';
import { IconArrowChevronRightArrowRight } from 'components/Icon/SVGIcons';
import { desktopFirstV3 } from 'style/breakpointsv3';
import { toScreen } from 'utils/media-query/responsive.util';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

const Title = styled.h2`
  font-size: 0;
  max-width: 500px;
  text-align: center;
`;

const CtaFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 32px;
  padding-left: 20px;
  background-color: ${colorsV2.primary100};
  border-radius: 24px;

  ${toScreen(desktopFirstV3.md)} {
    gap: 32px;
  }

  ${toScreen(desktopFirstV3.sm)} {
    gap: 24px;
    flex-direction: column;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  width: 140px;
  height: 48px;
  position: relative;
  flex-shrink: 0;

  img {
    position: absolute;
    width: 100%;
    height: auto;
    object-fit: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    ${toScreen(desktopFirstV3.sm)} {
      transform: translate(-50%, -62.5%);
    }
  }
`;

const CtaFooterText = styled(Typography)`
  flex: 1;
`;

const CtaButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 16px;
  background-color: ${colorsV2.white100};
  border-radius: 12px;
  border: none;
  cursor: pointer;
  flex-shrink: 0;
`;

export const IeltsJuniorAdvantage = ({ advantages, onRegisterClick }) => {
  const { title, items } = advantages;
  return (
    <SectionV3>
      <ResponsiveContainer>
        <Wrapper>
          <Title>
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              color={colorsV2.black100}
              v3
            >
              {title}
            </Typography>
          </Title>
          <AdvantageList items={items} />
          <CtaFooter>
            <ImageWrapper>
              <LazyImage
                handle="cm8r8fzi46wbu08zv6fw1wfc3"
                width={168}
                height={199}
              />
            </ImageWrapper>
            <CtaFooterText variant="bold/24-32" color={colorsV2.white100}>
              Đăng ký ghi danh sớm để nhận ưu đãi đặc biệt
            </CtaFooterText>
            <CtaButton onClick={onRegisterClick}>
              <Typography
                variant="semi-bold/16-24"
                color={colorsV2.neutral180}
                type="span"
              >
                Đăng ký ngay
              </Typography>
              <IconArrowChevronRightArrowRight />
            </CtaButton>
          </CtaFooter>
        </Wrapper>
      </ResponsiveContainer>
    </SectionV3>
  );
};
