/**
 * Converts a hex color and opacity to a hex color with alpha
 * @param {string} hex - The hex color code (3 or 6 digits)
 * @param {number} opacity - Opacity value between 0 and 1
 * @returns {string} 8-digit hex color with alpha
 */
export function hexWithAlpha(hex, opacity) {
  // Remove # if present
  hex = hex.replace('#', '');

  // Convert 3-digit hex to 6-digit
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Validate hex length
  if (hex.length !== 6) {
    throw new Error('Invalid hex color format');
  }

  // Convert opacity to hex
  const alpha = Math.round(opacity * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${hex}${alpha}`;
}
