import { Typography } from 'components/DesignSystemV2/Typography';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { RoadmapDetails } from './RoadmapDetails';
import { SectionV3 } from 'components/SectionV3';
import Button from 'components/Button';

const Section = styled(SectionV3)`
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Title = styled.h2`
  font-size: 0;
  max-width: 420px;
  text-align: center;
`;

const Cta = styled(Button)`
  border-radius: 8px;
`;

export const IeltsJuniorRoadmap = ({ data, onRegisterClick }) => {
  const { title, description, courses } = data;
  return (
    <Section>
      <ResponsiveContainer>
        <Wrapper>
          <HeaderWrapper>
            <Typography variant="regular/16-24" color={colorsV2.neutral120} v3>
              {description}
            </Typography>
            <Title>
              <Typography
                variant="bold/24-32"
                tabletVariant="bold/32-40"
                desktopVariant="bold/40-48"
                color={colorsV2.black100}
                type="span"
                v3
              >
                {title}
              </Typography>
            </Title>
          </HeaderWrapper>
          <RoadmapDetails details={courses} />
          <Cta onClick={onRegisterClick}>
            <Typography variant="bold/16-24" color={colorsV2.white100}>
              Đăng ký sớm giữ chỗ
            </Typography>
          </Cta>
        </Wrapper>
      </ResponsiveContainer>
    </Section>
  );
};
