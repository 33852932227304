import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

const Container = styled.div`
  perspective: 1000px;
  width: 100%;
  max-width: 892px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 80%;
    background-color: white;
    z-index: -1;
    border-radius: 50%;
    filter: blur(100px);
  }
`;

const BackgroundWrapper = styled(motion.div)`
  width: 100%;
  aspect-ratio: 892 / 395;
  background-image: url('${getAssetLinkByHandle({
    handle: 'cm8pbdab2483t07zupgbpn0yv',
    width: 892,
    height: 589
  })}');
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  transform-origin: center 110%;
`;

const ThoughtsWrapper = styled(motion.div)`
  aspect-ratio: 569 / 131;
  width: calc(569 / 891 * 100%);
  background-image: url('${getAssetLinkByHandle({
    handle: 'cm8zouzhq0pye07zy13gkmml2',
    width: 569,
    height: 131
  })}');
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ChildrenAnimation = () => {
  return (
    <Container>
      <ThoughtsWrapper
        initial={{
          opacity: 0,
          scale: 0.9
        }}
        animate={{
          opacity: 1,
          scale: 1
        }}
        transition={{
          delay: 1.5 + 0.3
        }}
      />
      <BackgroundWrapper
        initial={{
          opacity: 0,
          y: 120
        }}
        animate={{
          opacity: 1,
          y: 0
        }}
        transition={{
          duration: 1.5,
          type: 'spring',
          delay: 0.8
        }}
      />
    </Container>
  );
};
