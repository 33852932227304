import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

const Container = styled(motion.div)`
  max-width: 100%;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  max-width: 1366px;
`;

const BackgroundWrapper = styled(motion.div)`
  width: 1939px;
  min-width: 990px;
  aspect-ratio: 1939 / 689;
  background-image: url('${getAssetLinkByHandle({
    handle: 'cm8pllvxg1hg30716ex6png85',
    width: 1939,
    height: 689
  })}');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transform-origin: bottom;
`;

export const BackgroundAnimation = () => {
  const { scrollY } = useViewportScroll();

  const bgY = useTransform(scrollY, [0, 600], [0, 100]);

  return (
    <Container style={{ y: bgY, x: '-50%' }}>
      <BackgroundWrapper
        initial={{
          opacity: 0,
          y: 200,
          x: '-50%'
        }}
        animate={{
          opacity: 1,
          y: 0,
          x: '-50%'
        }}
        transition={{
          duration: 1.5,
          type: 'spring',
          delay: 0.3
        }}
      />
    </Container>
  );
};
